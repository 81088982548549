import General1 from "../../../../../images/gallery/general/1.jpg";
import General2 from "../../../../../images/gallery/general/2.jpg";
import General3 from "../../../../../images/gallery/general/3.jpg";
import General4 from "../../../../../images/gallery/general/4.jpg";
import General5 from "../../../../../images/gallery/general/5.jpg";
import General6 from "../../../../../images/gallery/general/6.jpg";
import General7 from "../../../../../images/gallery/general/7.jpg";
import General8 from "../../../../../images/gallery/general/8.jpg";
import General9 from "../../../../../images/gallery/general/9.jpg";
import General10 from "../../../../../images/gallery/general/10.jpg";

const GENERAL = [
  General1,
  General2,
  General3,
  General4,
  General5,
  General6,
  General7,
  General8,
  General9,
  General10,
];

export default GENERAL;
