import React from 'react';
import { Helmet } from 'react-helmet';

const defaultDescription = "Bienvenido a Punta Medano Apart! Una ubicación excepcional a pocos metros de la playa";

interface SEOProps {
  description?: string
  lang?: string
  meta?: [
    {
      name: string
      content: string
    },
  ]
  title?: string
  canonical?: string
  noIndex?: boolean

  /**
   * Overrides the og:title meta property if it should be different from the
   * page title
   */
  ogTitle?: string
}

const SEO: React.FC<SEOProps> = ({
  description,
  lang,
  meta,
  title,
  ogTitle,
  noIndex,
  canonical,
}) => (
  <Helmet
    htmlAttributes={{
      land: lang || 'es',
    }}
    title={title}
    defaultTitle="Punta Medano Apart | Valeria del Mar"
    titleTemplate={`%s | Punta Medano Apart | Valeria del Mar`}
    link={[
      {
        rel: 'canonical',
        href: `https://puntamedanoapart.com.ar${canonical ? `/${canonical}` : null}`
      }
    ]}
    meta={[
      {
        name: `description`,
        content: description || defaultDescription,
      },
      {
        name: `robots`,
        content: `${noIndex ? `noindex, nofollow` : `index, follow`}`,
      },
      {
        property: `og:title`,
        content: ogTitle || title || 'Punta Medano Apart | Valeria del Mar',
      },
      {
        property: `og:description`,
        content: description || defaultDescription,
      },
      {
        property: `og:type`,
        content: `website`,
      },
    ].concat(meta || [])}
  />
);

export default SEO
