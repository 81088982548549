import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../../images/pm-logo-dark.png";
import LogoFooter from "../../../images/pm-logo.png";
import ScrollToTop from "../ScrollToTop";

import "./index.scss";

export const Layout: React.FC = ({ children }) => (
  <>
    <ScrollToTop />
    <header>
      <nav
        className="navbar navbar-expand-lg navbar-light navbar-custom"
        id="navbar"
      >
        <a className="navbar-brand" href="./index.html">
          <img className="logo" src={Logo} alt="Logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse collapse-custom"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink activeClassName="active" to="/" exact>
                Inicio
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink activeClassName="active" to="/servicios">
                Servicios
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink activeClassName="active" to="/cabanias">
                Cabañas
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink activeClassName="active" to="/ubicacion">
                Ubicacion
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink activeClassName="active" to="/contacto">
                Contacto
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>

      <div className="banner-container">
        <div className="banner-block">
          <div className="banner-title">¿VACACIONES?</div>
          <div className="banner-subtitle">¡Encontraste el lugar indicado!</div>
        </div>
      </div>
      <form className="booking-container">
        <div id="booking-form">
          <div className="booking-input-group">
            <div className="booking-input">
              <input
                type="text"
                placeholder="Nombre"
                name="name"
                className="form-control"
                required
              />
            </div>
          </div>
          <div className="booking-input-group">
            <div className="booking-input">
              <input
                type="text"
                placeholder="Ciudad"
                name="city"
                className="form-control"
                required
              />
            </div>
          </div>
          <div className="booking-input-group">
            <div className="booking-input">
              <input
                type="email"
                placeholder="Email"
                name="email"
                className="form-control"
                required
              />
            </div>
          </div>
          <div className="booking-input-group small-input-group">
            <div className="booking-input">
              <select name="pax" className="form-control" id="pax">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
              </select>
            </div>
          </div>
          <div className="booking-input-group">
            <div className="booking-input">
              <input
                type="text"
                placeholder="Check-in"
                name="checkin"
                className="form-control checkin"
                id="checkin"
                required
              />
            </div>
          </div>
          <div className="booking-input-group">
            <div className="booking-input">
              <input
                type="text"
                placeholder="Check-out"
                name="checkout"
                className="form-control checkout"
                id="checkout"
                required
              />
            </div>
          </div>
          <div className="booking-input-group small-input-group">
            <div className="booking-input">
              <button type="submit" id="send" className="btn btn-custom">
                Enviar
              </button>
              <button
                type="submit"
                id="sending"
                className="btn btn-custom"
                style={{ display: "none" }}
                disabled
              >
                Enviando
              </button>
            </div>
          </div>
        </div>
        <div
          id="msgSubmit"
          className="h3 text-center"
          style={{
            width: "100%",
            height: "100%",
            display: "none",
          }}
        >
          La consulta ya fue enviada, en un breve momento le responderemos el
          email!
        </div>
      </form>
    </header>
    {children}
    <footer>
      <div className="footer-content">
        <div className="logo-container">
          <img className="footer-logo" src={LogoFooter} alt="Logo" />
        </div>
        <div className="data-container">
          <span>
            <i className="services-placeholder"></i>Belfast 41, Valeria del mar,
            Argentina
          </span>
          <span>
            <i className="services-technology"></i>(02254) 408840
          </span>
          <span>
            <i className="services-mail"></i>puntamedano@gmail.com
          </span>
        </div>
        <div className="social-container">
          <a href="https://www.facebook.com/PuntaMedano/" target="_blank">
            <i className="services-social"></i>
            <span>Punta Medano</span>
          </a>
        </div>
      </div>
    </footer>
  </>
);
