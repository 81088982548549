import Corvina1 from "../../../../../images/gallery/corvina/1.jpg";
import Corvina2 from "../../../../../images/gallery/corvina/2.jpg";
import Corvina3 from "../../../../../images/gallery/corvina/3.jpg";
import Corvina4 from "../../../../../images/gallery/corvina/4.jpg";
import Corvina5 from "../../../../../images/gallery/corvina/5.jpg";
import Corvina6 from "../../../../../images/gallery/corvina/6.jpg";
import Corvina7 from "../../../../../images/gallery/corvina/7.jpg";
import Corvina8 from "../../../../../images/gallery/corvina/8.jpg";
import Corvina9 from "../../../../../images/gallery/corvina/9.jpg";

const CORVINA = [
  Corvina1,
  Corvina2,
  Corvina3,
  Corvina4,
  Corvina5,
  Corvina6,
  Corvina7,
  Corvina8,
  Corvina9,
];

export default CORVINA;
