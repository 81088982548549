import Gaviota1 from "../../../../../images/gallery/gaviota/1.jpg";
import Gaviota2 from "../../../../../images/gallery/gaviota/2.jpg";
import Gaviota3 from "../../../../../images/gallery/gaviota/3.jpg";
import Gaviota4 from "../../../../../images/gallery/gaviota/4.jpg";
import Gaviota5 from "../../../../../images/gallery/gaviota/5.jpg";
import Gaviota6 from "../../../../../images/gallery/gaviota/6.jpg";
import Gaviota7 from "../../../../../images/gallery/gaviota/7.jpg";
import Gaviota8 from "../../../../../images/gallery/gaviota/8.jpg";

const GAVIOTA = [
  Gaviota1,
  Gaviota2,
  Gaviota3,
  Gaviota4,
  Gaviota5,
  Gaviota6,
  Gaviota7,
  Gaviota8,
];

export default GAVIOTA;
