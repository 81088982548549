import axios from "axios";
import React, { useEffect, useState } from "react";

import SEO from "../../shared/SEO";
import { FAQ } from "./FAQ";

import "./index.scss";

const API_PATH = "contact-form.php";

export const Contacto: React.FC = () => {
  const [contactname, setName] = useState("");
  const [contactemail, setEmail] = useState("");
  const [contactmessage, setMessage] = useState("");
  const [contactpax, setPax] = useState("1");
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    const timeId = setTimeout(() => {
      error && setError("");
      success && setSuccess("");
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [error, success]);

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    setIsSending(true);
    const data = {
      contactname,
      contactemail,
      contactmessage,
      contactpax,
    };
    axios
      .post(API_PATH, data)
      .then((result: any) => {
        if (result?.data === "success") {
          setIsSending(false);
          setSuccess(
            "Mensaje enviado correctamente! Pronto estaremos enviandole detalles a su solicitud!"
          );
        } else {
          throw new Error("error");
        }
      })
      .catch((_) => {
        setError(
          "Ocurrió un error inesperado. Por favor revisa los campos o contáctese por teléfono al 02254-408840"
        );
        setIsSending(false);
      });
  };

  const disableSumbitButton =
    !contactname || !contactemail || !contactmessage || !contactpax;

  return (
    <>
      <SEO title="Contacto" canonical="contacto" />
      <main>
        <section className="section-intro">
          <div className="section-title">
            <h1>
              ¡Haga su consulta personalizada y quitese todas las dudas que
              tenga!
            </h1>
          </div>
        </section>
        <section className="section-form">
          <div className="row">
            <div className="col-md-6">
              <form id="contact-form">
                <div className="form-group">
                  <label>
                    Nombre
                    <input
                      name="contact-name"
                      type="text"
                      className="form-control"
                      id="contact-name"
                      aria-describedby="emailHelp"
                      placeholder="Escribe tu nombre"
                      required
                      onChange={(e) => setName(e.target.value)}
                      value={contactname}
                    />
                  </label>
                </div>
                <div className="form-group">
                  <label>
                    Email address
                    <input
                      name="contact-email"
                      type="email"
                      className="form-control"
                      id="contact-email"
                      aria-describedby="emailHelp"
                      placeholder="Escribe tu email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      value={contactemail}
                    />
                    <small id="emailHelp" className="form-text text-muted">
                      No compartiremos su correo electrónico con nadie más.
                    </small>
                  </label>
                </div>
                <div className="form-group">
                  <label>
                    Cantidad de personas
                    <select
                      name="contact-pax"
                      className="form-control"
                      required
                      onChange={(e) => setPax(e.target.value)}
                      value={contactpax}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="+6">+6</option>
                    </select>
                  </label>
                </div>
                <div className="form-group">
                  <label>
                    Mensaje
                    <textarea
                      name="contact-message"
                      className="form-control"
                      id="contact-message"
                      rows={4}
                      placeholder="Escribe tu mensaje"
                      required
                      onChange={(e) => setMessage(e.target.value)}
                      value={contactmessage}
                    ></textarea>
                  </label>
                </div>
                <button
                  type="submit"
                  id="send"
                  className="btn btn-custom"
                  disabled={disableSumbitButton}
                  onClick={handleFormSubmit}
                >
                  {isSending ? "Enviando..." : "Enviar"}
                </button>
                {success && (
                  <div
                    id="msgSubmit"
                    className="h3 text-center"
                    style={{
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    {success}
                  </div>
                )}
              </form>

              {error && (
                <div
                  id="error_message"
                  style={{ width: "100%", height: "100%" }}
                >
                  {error}
                </div>
              )}
            </div>
            <FAQ />
          </div>
        </section>
      </main>
    </>
  );
};
