import Caracola1 from "../../../../../images/gallery/caracola/1.jpg";
import Caracola2 from "../../../../../images/gallery/caracola/2.jpg";
import Caracola3 from "../../../../../images/gallery/caracola/3.jpg";
import Caracola4 from "../../../../../images/gallery/caracola/4.jpg";
import Caracola5 from "../../../../../images/gallery/caracola/5.jpg";
import Caracola6 from "../../../../../images/gallery/caracola/6.jpg";

const CARACOLA = [
  Caracola1,
  Caracola2,
  Caracola3,
  Caracola4,
  Caracola5,
  Caracola6,
];

export default CARACOLA;
