import React, { useState } from "react";

import SEO from "../../shared/SEO";
import ServicesImg from "../../../images/services-img.png";

import "./index.scss";

export const Servicios: React.FC = () => {
  const [expand, toggleExpand] = useState<boolean>(false);
  return (
    <>
      <SEO title="Servicios" canonical="servicios" />
      <main>
        <section className="section-intro">
          <div className="section-title">
            <h1>Disfrutá tus vacaciones</h1>
          </div>
          <div className="section-subtitle">
            Estos son los servicios que ofrecemos. ¡Todo pensado para que
            aproveches al máximo tu estadía!
          </div>
        </section>
        <section className="section-services-block">
          <div className="row">
            <div className="col-md-6 desc-content">
              <div>
                <p>
                  En Punta Medano Apart tenemos los mejores servicios de
                  hotelería. Cada estadía en el mismo incluye desayuno hasta las
                  10 hs., servicio de mucama, servicio de limpieza y ropa
                  blanca, internet con Wi-Fi.
                </p>
                <p>
                  En planta se encuentra la piscina (climatizada y descubierta),
                  el solarium, juegos al aire libre para niños, la sala de
                  encuentro, variados films, juegos de playa, juegos de mesa y
                  mucho más! Además de la recepción a su disposición.
                </p>
              </div>
            </div>
            <div className="col-md-6 services-img">
              <img src={ServicesImg} alt="Espacios comunes" />
            </div>
          </div>
        </section>
        <section className="section-services">
          <div className="services-container-servicios">
            <div className="row">
              <div className="col-md-4">
                <div className="item">
                  <div className="service-icon">
                    <i className="services-coffee-cup"></i>
                  </div>
                  <div className="service-title">
                    <p>Desayuno en la cabaña</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="item">
                  <div className="service-icon">
                    <i className="services-swimming-pool"></i>
                  </div>
                  <div className="service-title">
                    <p>Pileta climatizada</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="item">
                  <div className="service-icon">
                    <i className="services-hanger"></i>
                  </div>
                  <div className="service-title">
                    <p>Ropa blanca</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="item">
                  <div className="service-icon">
                    <i className="services-cleaning"></i>
                  </div>
                  <div className="service-title">
                    <p>Servicio de limpieza</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="item">
                  <div className="service-icon">
                    <i className="services-parking"></i>
                  </div>
                  <div className="service-title">
                    <p>Cocheras externas</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="item">
                  <div className="service-icon">
                    <i className="services-garage"></i>
                  </div>
                  <div className="service-title">
                    <p>Cocheras</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="item">
                  <div className="service-icon">
                    <i className="services-reception-bell"></i>
                  </div>
                  <div className="service-title">
                    <p>Recepción</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="item">
                  <div className="service-icon">
                    <i className="services-barbecue"></i>
                  </div>
                  <div className="service-title">
                    <p>Quinchos y parrilleros</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="item">
                  <div className="service-icon">
                    <i className="services-ventilator"></i>
                  </div>
                  <div className="service-title">
                    <p>Ventiladores</p>
                  </div>
                </div>
              </div>
            </div>
            {expand && (
              <div className="row">
                <div className="col-md-4">
                  <div className="item">
                    <div className="service-icon">
                      <i className="services-heater"></i>
                    </div>
                    <div className="service-title">
                      <p>Estufas</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="item">
                    <div className="service-icon">
                      <i className="services-air-conditioner"></i>
                    </div>
                    <div className="service-title">
                      <p>
                        Aire Acondicionado<span>*</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="item">
                    <div className="service-icon">
                      <i className="services-safety-box"></i>
                    </div>
                    <div className="service-title">
                      <p>Caja de seguridad</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="item">
                    <div className="service-icon">
                      <i className="services-football"></i>
                    </div>
                    <div className="service-title">
                      <p>Objetos y juegos de playa</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="item">
                    <div className="service-icon">
                      <i className="services-horse"></i>
                    </div>
                    <div className="service-title">
                      <p>Juegos de mesa</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="item">
                    <div className="service-icon">
                      <i className="services-teddy-bear"></i>
                    </div>
                    <div className="service-title">
                      <p>Juegos para niños</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="item">
                    <div className="service-icon">
                      <i className="services-wifi"></i>
                    </div>
                    <div className="service-title">
                      <p>WiFi con internet</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="item">
                    <div className="service-icon">
                      <i className="services-wave"></i>
                    </div>
                    <div className="service-title">
                      <p>
                        Vista al mar<span>*</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="item">
                    <div className="service-icon">
                      <i className="services-chef"></i>
                    </div>
                    <div className="service-title">
                      <p>
                        Noche de menú del chef<span>**</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 leyends">
                  <p>
                    <strong>*</strong>Ciertas cabañas en particular{" "}
                    <strong>**</strong>Consultar en recepción
                  </p>
                </div>
              </div>
            )}
            <button
              type="button"
              className="btn btn-dark btn-lg btn-block btn-margin"
              onClick={() => toggleExpand(!expand)}
            >
              {expand ? "Mostrar todos" : "Mostrar menos"}
            </button>
          </div>
        </section>
      </main>
    </>
  );
};
