import Almeja1 from "../../../../../images/gallery/almeja/1.jpg";
import Almeja2 from "../../../../../images/gallery/almeja/2.jpg";
import Almeja3 from "../../../../../images/gallery/almeja/3.jpg";
import Almeja4 from "../../../../../images/gallery/almeja/4.jpg";
import Almeja5 from "../../../../../images/gallery/almeja/5.jpg";
import Almeja6 from "../../../../../images/gallery/almeja/6.jpg";

const ALMEJA = [Almeja1, Almeja2, Almeja3, Almeja4, Almeja5, Almeja6];

export default ALMEJA;
