import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { Layout } from './components/shared/layout';
import {
  Cabanias,
  Contacto,
  Inicio,
  Servicios,
  Ubicacion
} from './components/pages';

import './App.css';

const App: React.FC = () => {
  return (
    <>
      <Router>
        <Layout>
          <Switch>
            <Route path="/servicios">
              <Servicios />
            </Route>
            <Route path="/cabanias">
              <Cabanias />
            </Route>
            <Route path="/ubicacion">
              <Ubicacion />
            </Route>
            <Route path="/contacto">
              <Contacto />
            </Route>
            <Route path="/">
              <Inicio />
            </Route>
          </Switch>
        </Layout>
      </Router>
    </>
  );
}

export default App;
