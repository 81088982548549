import React from "react";

import "./index.scss";

export const FAQ: React.FC = () => (
  <div className="col-md-6">
    <h4 className="faq-title">Preguntas frecuentes</h4>
    <div id="accordion" role="tablist">
      <div className="card">
        <div className="card-header" role="tab" id="faqOne">
          <h5 className="mb-0">
            <a
              className="collapsed"
              data-toggle="collapse"
              href="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              1. ¿Puedo cancelar o modificar una reserva?, ¿Qué gastos puede
              generar una cancelación o modificación de reserva?
            </a>
          </h5>
        </div>

        <div
          id="collapseOne"
          className="collapse"
          role="tabpanel"
          aria-labelledby="faqOne"
          data-parent="#accordion"
        >
          <div className="card-body">
            <strong>Sí</strong>, sí puedes. Pero ten en cuenta que dicha
            anulación puede conllevar unos gastos de cancelación. Al realizar la
            reserva, se informa de la política de cancelación, o bien ésta
            figura en las condiciones generales.
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header" role="tab" id="faqTwo">
          <h5 className="mb-0">
            <a
              className="collapsed"
              data-toggle="collapse"
              href="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              2. ¿Cómo puedo pagar una reserva?
            </a>
          </h5>
        </div>
        <div
          id="collapseTwo"
          className="collapse"
          role="tabpanel"
          aria-labelledby="faqTwo"
          data-parent="#accordion"
        >
          <div className="card-body">
            <p>
              Al finalizar la reserva te mostraremos las diferentes formas de
              pago de la reserva. Puedes pagar mediante tarjeta de crédito,
              transferencia online, transferencia bancaria... En ocasiones y
              según la antelación con que hagas la reserva, no todas estas
              opciones estarán disponibles.
            </p>
            <p>
              Normalmente abonarás el 30% a la hora de realizar la reserva y el
              importe restante el día de la entrada.
            </p>
            <p>
              En ocasiones podrás beneficiarte de promociones especiales las
              cuales puedan modificar los importes y fechas de pago.
            </p>
            <p>
              En el caso de que la reserva sea de pago directamente el día de
              llegada, la cual vendrá claramente indicada como tal en la
              reserva, se pedirán los datos personales únicamente como garantía.
            </p>
            <p>
              Eso sí, si se trata de una tarifa no reembolsable se te cargará
              directamente el importe de tu reserva.
            </p>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header" role="tab" id="faqThree">
          <h5 className="mb-0">
            <a
              className="collapsed"
              data-toggle="collapse"
              href="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              3. ¿Tengo que añadir a los niños o bebés en la reserva?
            </a>
          </h5>
        </div>
        <div
          id="collapseThree"
          className="collapse"
          role="tabpanel"
          aria-labelledby="faqThree"
          data-parent="#accordion"
        >
          <div className="card-body">
            <p>
              <strong>Sí, es necesario incluir a todos los pasajeros.</strong>{" "}
              Por pasajero se entiende toda aquella persona física que vaya a
              acudir a la cabaña reservada, indistintamente de que sea bebé,
              niño o adulto.
            </p>
            <p>
              Tenemos que tener una previsión de las personas y edades de los
              ocupantes, puesto que hay algunos tipos de habitación en las
              cuales no caben una cama extra o cuna.
            </p>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header" role="tab" id="faqFour">
          <h5 className="mb-0">
            <a
              className="collapsed"
              data-toggle="collapse"
              href="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              4. ¿Cuál es el horario habitual para la entrada y salida en las
              cabañas?
            </a>
          </h5>
        </div>
        <div
          id="collapseFour"
          className="collapse"
          role="tabpanel"
          aria-labelledby="faqFour"
          data-parent="#accordion"
        >
          <div className="card-body">
            <p>
              Como norma general las cabañas podrán ser utilizadas a partir de
              las <strong>14 horas del día de llegada</strong> y deberán quedar
              libres antes de las <strong>10 horas del día de salida</strong>.
            </p>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header" role="tab" id="faqFive">
          <h5 className="mb-0">
            <a
              className="collapsed"
              data-toggle="collapse"
              href="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              5. ¿Tengo que avisar al hotel si preveo llegar más tarde de las
              18.00 hrs?
            </a>
          </h5>
        </div>
        <div
          id="collapseFive"
          className="collapse"
          role="tabpanel"
          aria-labelledby="faqFive"
          data-parent="#accordion"
        >
          <div className="card-body">
            <p>
              <strong>Sí</strong>, debes avisar si tienes pensado llegar después
              de las 18.00 hrs. No hay ningún problema, es solamente para dejar
              al personal a la espera de su llegada
            </p>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header" role="tab" id="faqSix">
          <h5 className="mb-0">
            <a
              className="collapsed"
              data-toggle="collapse"
              href="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              6. ¿Puedo alojarme con mi mascota?
            </a>
          </h5>
        </div>
        <div
          id="collapseSix"
          className="collapse"
          role="tabpanel"
          aria-labelledby="faqSix"
          data-parent="#accordion"
        >
          <div className="card-body">
            <p>
              <strong>No</strong>, no aceptamos ningún tipo de mascota en
              nuestro establecimiento.
            </p>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header" role="tab" id="faqSeven">
          <h5 className="mb-0">
            <a
              className="collapsed"
              data-toggle="collapse"
              href="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              7. ¿Hay cocheras techadas?
            </a>
          </h5>
        </div>
        <div
          id="collapseSeven"
          className="collapse"
          role="tabpanel"
          aria-labelledby="faqSeven"
          data-parent="#accordion"
        >
          <div className="card-body">
            <p>
              <strong>Sí</strong>, tenemos cocheras techadas, las mismas tienen
              un costo adicional el cual se puede saber haciendo una consulta
              desde el formulario de contacto.
            </p>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header" role="tab" id="faqEight">
          <h5 className="mb-0">
            <a
              className="collapsed"
              data-toggle="collapse"
              href="#collapseEight"
              aria-expanded="false"
              aria-controls="collapseEight"
            >
              8. ¿Aceptan grupos de jovenes?
            </a>
          </h5>
        </div>
        <div
          id="collapseEight"
          className="collapse"
          role="tabpanel"
          aria-labelledby="faqEight"
          data-parent="#accordion"
        >
          <div className="card-body">
            <p>
              <strong>No</strong>, no aceptamos grupos de jovenes o
              adolescentes, este complejo es único para familias y adultos
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);
