import React from "react";
import GoogleMapReact from "google-map-react";

import SEO from "../../shared/SEO";

import "./index.scss";
import Marker from "./Marker";

export const Ubicacion: React.FC = () => (
  <>
    <SEO title="Ubicación" canonical="ubicacion" />
    <main>
      <div className="map-container">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDhjx64o08qs2QIgpk610wfrs9K5rNCbRA" }}
          defaultCenter={{
            lat: -37.15019522809441,
            lng: -56.88154565456235,
          }}
          defaultZoom={13}
          yesIWantToUseGoogleMapApiInternals
        >
          <Marker
            lat={-37.15019522809441}
            lng={-56.88154565456235}
            text={"Estamos aquí!"}
          />
        </GoogleMapReact>
      </div>
    </main>
  </>
);
