import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import "./index.scss";

interface Props {
  images: string[];
  title?: string;
}

const Gallery: React.FC<Props> = ({ images, title = "" }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div className="image-thumb-container">
        <span>{title}</span>
        <img
          className="image-thumb"
          src={images[0]}
          alt="Cabaña"
          onClick={() => setIsOpen(true)}
        />
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          mainSrcThumbnail={images[photoIndex]}
          nextSrcThumbnail={images[(photoIndex + 1) % images.length]}
          prevSrcThumbnail={
            images[(photoIndex + images.length - 1) % images.length]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

export default Gallery;
