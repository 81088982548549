import Brotola1 from "../../../../../images/gallery/brotola/1.jpg";
import Brotola2 from "../../../../../images/gallery/brotola/2.jpg";
import Brotola3 from "../../../../../images/gallery/brotola/3.jpg";
import Brotola4 from "../../../../../images/gallery/brotola/4.jpg";
import Brotola5 from "../../../../../images/gallery/brotola/5.jpg";
import Brotola6 from "../../../../../images/gallery/brotola/6.jpg";
import Brotola7 from "../../../../../images/gallery/brotola/7.jpg";
import Brotola8 from "../../../../../images/gallery/brotola/8.jpg";

const BROTOLA = [
  Brotola1,
  Brotola2,
  Brotola3,
  Brotola4,
  Brotola5,
  Brotola6,
  Brotola7,
  Brotola8,
];

export default BROTOLA;
