import React from "react";

import SEO from "../../shared/SEO";

import "./index.scss";
import Gallery from "./Gallery";

import Almeja from "./Gallery/Imagenes/almeja";
import Brotola from "./Gallery/Imagenes/brotola";
import Cangrejo from "./Gallery/Imagenes/cangrejo";
import Caracola from "./Gallery/Imagenes/caracola";
import Corvina from "./Gallery/Imagenes/corvina";
import Erizo from "./Gallery/Imagenes/erizo";
import Gaviota from "./Gallery/Imagenes/gaviota";
import General from "./Gallery/Imagenes/general";
import Medusa from "./Gallery/Imagenes/medusa";
import Pulpo from "./Gallery/Imagenes/pulpo";

export const Cabanias: React.FC = () => (
  <>
    <SEO title="Cabañas" canonical="cabanias" />
    <main>
      <section className="section-intro">
        <div className="section-title">
          <h1>¡Visite la galería de imágenes de nuestras cabañas!</h1>
        </div>
        <div className="section-subtitle">
          Ofrecemos cabañas totalmente equipadas y con un increible vista al mar
        </div>
        <div className="section-description">
          <p>
            Encuentre las comodidades que más desea y el mejor precio del rubro
            en el mismo lugar.
          </p>
        </div>
      </section>
      <section className="section-rooms-gallery">
        <div className="room-block">
          <div className="room-block-title">El complejo:</div>
          <div className="rooms-container">
            <div className="mix">
              <Gallery images={General} />
            </div>
          </div>
        </div>
        <div className="room-block">
          <div className="room-block-title">Cabaña para 3 personas:</div>
          <div className="rooms-container">
            <div className="mix">
              <Gallery images={Almeja} title="Almeja" />
            </div>
          </div>
        </div>
        <div className="room-block">
          <div className="room-block-title">Cabañas para 4 personas:</div>
          <div className="rooms-container">
            <div className="mix">
              <Gallery images={Erizo} title="Erizo" />
            </div>

            <div className="mix">
              <Gallery images={Cangrejo} title="Cangrejo" />
            </div>

            <div className="mix">
              <Gallery images={Caracola} title="Caracola" />
            </div>

            <div className="mix">
              <Gallery images={Pulpo} title="Pulpo" />
            </div>

            <div className="mix">
              <Gallery images={Medusa} title="Medusa" />
            </div>
          </div>
        </div>
        <div className="room-block">
          <div className="room-block-title">Cabañas para 6 personas:</div>
          <div className="rooms-container">
            <div className="mix">
              <Gallery images={Gaviota} title="Gaviota" />
            </div>
            <div className="mix">
              <Gallery images={Corvina} title="Corvina" />
            </div>
            <div className="mix">
              <Gallery images={Brotola} title="Brotola" />
            </div>
          </div>
        </div>
      </section>
    </main>
  </>
);
