import Cangrejo1 from "../../../../../images/gallery/cangrejo/1.jpg";
import Cangrejo2 from "../../../../../images/gallery/cangrejo/2.jpg";
import Cangrejo3 from "../../../../../images/gallery/cangrejo/3.jpg";
import Cangrejo4 from "../../../../../images/gallery/cangrejo/4.jpg";
import Cangrejo5 from "../../../../../images/gallery/cangrejo/5.jpg";
import Cangrejo6 from "../../../../../images/gallery/cangrejo/6.jpg";

const CANGREJO = [
  Cangrejo1,
  Cangrejo2,
  Cangrejo3,
  Cangrejo4,
  Cangrejo5,
  Cangrejo6,
];

export default CANGREJO;
