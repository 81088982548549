import Medusa1 from "../../../../../images/gallery/medusa/1.jpg";
import Medusa2 from "../../../../../images/gallery/medusa/2.jpg";
import Medusa3 from "../../../../../images/gallery/medusa/3.jpg";
import Medusa4 from "../../../../../images/gallery/medusa/4.jpg";
import Medusa5 from "../../../../../images/gallery/medusa/5.jpg";
import Medusa6 from "../../../../../images/gallery/medusa/6.jpg";

const MEDUSA = [Medusa1, Medusa2, Medusa3, Medusa4, Medusa5, Medusa6];

export default MEDUSA;
