import React from "react";
import { Link } from "react-router-dom";

import SEO from "../../shared/SEO";
import Gallery1 from "../../../images/home-gallery/1.jpg";
import Gallery2 from "../../../images/home-gallery/2.jpg";
import Gallery3 from "../../../images/home-gallery/3.jpg";
import Gallery4 from "../../../images/home-gallery/4.jpg";
import Gallery5 from "../../../images/home-gallery/5.jpg";

import "./index.scss";

export const Inicio: React.FC = () => (
  <>
    <SEO title="Inicio" canonical="inicio" />
    <main>
      <section className="section-intro">
        <div className="section-title">
          <h1>Bienvenido a Punta Medano Apart</h1>
        </div>
        <div className="section-subtitle">
          Una ubicación excepcional a pocos metros de la playa
        </div>
        <div className="section-description">
          <p>
            A solo pasos del centro, del mar y de los bosques de Cariló, Punta
            Medano le da la bienvenida a un encantador apart situado en la mejor
            zona de Valeria del Mar.
          </p>
          <p>
            Cerca del mismo, pueden admirarse hermosas playas, el estupendo
            centro comercial de la ciudad y excelentes lugares para disfrutar a
            pleno su estadía en la ciudad.
          </p>
        </div>
      </section>
      <section className="section-rooms">
        <div className="section-title">
          <h1>Cabañas</h1>
        </div>
        <div className="section-subtitle">¡Descubre nuestro complejo!</div>
        <div className="section-description">
          <p>
            Perfecto para personas o parejas, para familias o amigos que visitan
            Valeria del Mar.
          </p>
        </div>
        <div className="rooms-gallery">
          {/* <!-- Swiper --> */}
          <div className="swiper-container gallery-rooms">
            <div className="swiper-wrapper">
              <div
                className="swiper-slide"
                style={{
                  backgroundImage: `url(${Gallery1})`,
                }}
              ></div>
              <div
                className="swiper-slide"
                style={{
                  backgroundImage: `url(${Gallery2})`,
                }}
              ></div>
              <div
                className="swiper-slide"
                style={{
                  backgroundImage: `url(${Gallery3})`,
                }}
              ></div>
              <div
                className="swiper-slide"
                style={{
                  backgroundImage: `url(${Gallery4})`,
                }}
              ></div>
              <div
                className="swiper-slide"
                style={{
                  backgroundImage: `url(${Gallery5})`,
                }}
              ></div>
            </div>
            {/* <!-- Add Arrows --> */}
            <div className="swiper-button-next swiper-button-white"></div>
            <div className="swiper-button-prev swiper-button-white"></div>
          </div>
        </div>
        <Link
          to="/cabanias"
          type="button"
          className="btn btn-light btn-lg btn-block btn-margin"
        >
          Ver todas las cabañas
        </Link>
      </section>
      <section className="section-services">
        <div className="section-title">
          <h1>Servicios</h1>
        </div>
        <div className="section-subtitle">
          ¡Brindamos los mejores servicios para una total comodidad en su viaje!
        </div>
        <p />
        <p />
        <div className="services-container">
          <div className="service-item">
            <i className="services-wave"></i>
            <div className="service-desc">A metros de la playa</div>
          </div>
          <div className="service-item">
            <i className="services-parking"></i>
            <div className="service-desc">Estacionamientos exclusivos</div>
          </div>
          <div className="service-item">
            <i className="services-family"></i>
            <div className="service-desc">Ideal para familias</div>
          </div>
          <div className="service-item">
            <i className="services-swimming-pool"></i>
            <div className="service-desc">Pileta climatizada</div>
          </div>
          <div className="service-item">
            <i className="services-coffee-cup"></i>
            <div className="service-desc">Desayuno en tu cabaña</div>
          </div>
        </div>
        <Link
          to="/servicios"
          type="button"
          className="btn btn-dark btn-lg btn-block btn-margin"
        >
          Ver todos los servicios
        </Link>
      </section>
    </main>
  </>
);
