import Erizo1 from "../../../../../images/gallery/erizo/1.jpg";
import Erizo2 from "../../../../../images/gallery/erizo/2.jpg";
import Erizo3 from "../../../../../images/gallery/erizo/3.jpg";
import Erizo4 from "../../../../../images/gallery/erizo/4.jpg";
import Erizo5 from "../../../../../images/gallery/erizo/5.jpg";
import Erizo6 from "../../../../../images/gallery/erizo/6.jpg";

const ERIZO = [Erizo1, Erizo2, Erizo3, Erizo4, Erizo5, Erizo6];

export default ERIZO;
