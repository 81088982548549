import Pulpo1 from "../../../../../images/gallery/pulpo/1.jpg";
import Pulpo2 from "../../../../../images/gallery/pulpo/2.jpg";
import Pulpo3 from "../../../../../images/gallery/pulpo/3.jpg";
import Pulpo4 from "../../../../../images/gallery/pulpo/4.jpg";
import Pulpo5 from "../../../../../images/gallery/pulpo/5.jpg";
import Pulpo6 from "../../../../../images/gallery/pulpo/6.jpg";

const PULPO = [Pulpo1, Pulpo2, Pulpo3, Pulpo4, Pulpo5, Pulpo6];

export default PULPO;
